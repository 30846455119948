// 入参格式：
// [{
//   label: 'recommend',
//   label_en: 'recommend',
//   value: 0
// }, {
//   label: 'Most Popular',
//   label_en: 'Most Popular',
//   value: 8
// }, {
//   label: 'New Arrivals',
//   label_en: 'New Arrivals',
//   value: 9
// }]

<template>
  <ul 
    class="filter-ctn__select-menu"
    :class="{
      'filter-ctn__select-menu_bt': borderTop
    }"
  >
    <li
      v-for="(item, index) in menuList"
      :key="index"
      class="select-menu__li"
      :class="{ 'select-menu__li_active': activeIndex === index }"
      :adaflag="item.label"
      aria-hidden="true"
      role="button"
      :aria-label="item.label"
      @click="clickMenu(item, index)"
    >
      {{ item.label }}
      <i
        v-if="activeIndex === index"
        class="suiiconfont sui_icon_selected_16px"
      ></i>
    </li>
  </ul>
</template>

<script>
import { emitEvent } from '../utils'

export default {
  name: 'SelectMenu',
  props: {
    defaultSelectIndex: {
      type: [String, Number],
      default: ''
    },
    menuList: {
      type: Array,
      default: () => []
    },
    resetData: {
      type: Boolean,
      default: false
    },
    borderTop: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      activeIndex: ''
    }
  },
  watch: {
    resetData () {
      if (this.resetData) {
        this.clickMenu(this.menuList[0], 0, 'reset')
      }
    },
    defaultSelectIndex: {
      handler () {
        if (this.defaultSelectIndex === '') {
          this.activeIndex = ''
          return
        }

        this.activeIndex = this.defaultSelectIndex
      },
      immediate: true
    },
  },
  mounted () {
    this.$nextTick(() => {
      emitEvent(this.$parent, 'recordSelectMenu')
    })
  },
  methods: {
    clickMenu (item, index, from) {
      if (this.loading) return
      this.activeIndex =  index
      if (from == 'reset') return
      emitEvent(this.$parent, 'clickMenuItem', {
        from,
        payload: { data: item }
      })
    }
  }
}
</script>

<style lang="less">
.filter-ctn {
  &__select-menu {
    padding: 0 .32rem;
    background: #fff;
    max-height: 6.7rem;
    overflow-y: auto;
    &_bt {
      border-top: 1px solid #e5e5e5;
    }
    .select-menu {
      &__li {
        color: #666;
        height: 1.06rem;
        line-height: 1.06rem;
        &:not(:last-child) {
          .border-dpr(border-bottom, 1px, #e5e5e5);
        }
        &_active {
          color: @sui_color_main;
          font-weight: bold;
        }
        [class*="iconfont"] {
          .font-dpr(32px);
          .fr();
          font-weight: normal;
          color: @sui_color_main;
        }
      }
    }
  }
}
</style>
